<template>
    <div class="loader">
        <img src="@/assets/images/loaders/Infinity.svg" alt="">
    </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: "Loader"
})
</script>



<style scoped lang="scss">
.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.15);
}
</style>

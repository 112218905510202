<template>
    <header class="container">
        <a href="#" @click.prevent="logout">Logout</a>
    </header>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: "Header",
    methods: {
        logout() {
            axios.get(window.apiUrl + '/api/external/auth/logout').then(() => {
                this.$router.push({name: 'auth'});
            })
        }
    }
})
</script>


<style scoped lang="scss">
header {
    padding: 10px 0;
    display: flex;
    justify-content: flex-end;
}
</style>

import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Cookies from "js-cookie";

const checkAuth = async function () {
    if (!Cookies.get('access_token')) return false;

    try {
        const response = await axios.get(window.apiUrl + '/api/external/auth/me');
        return response.status === 200;
    } catch (e) {
        return false;
    }
}

const setAxiosRequestConfig = function () {
    const accessToken = Cookies.get('access_token');

    if (accessToken) {
        window.axios.interceptors.request.use(function (config) {
            config['headers']['Authorization'] = 'Bearer ' + Cookies.get('access_token');
            return config;
        }, function (error) {
            return Promise.reject(error);
        });
    }
}

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {
            middleware: "auth",
        },
        children: {}
    },
    {
        path: '/auth',
        name: 'auth',
        component: () => import('../views/AuthView.vue')
    },
    {
        path: "/(.*)*",
        redirect: '/'
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})


router.beforeEach(async (to, from, next) => {

    setAxiosRequestConfig();

    if (to.meta.middleware === "auth") {
        const check = await checkAuth()

        if (!check) {
            Cookies.remove('access_token')
            next({name: "auth"});
        } else {
            next();
        }
    } else {
        next();
    }

    // Scroll page to top on every route change
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
    });
});

export default router

<template>
    <Loader v-if="isLoading" />
    <Header/>
    <div class="home" v-if="customer">
        <div class="container">
            <section class="mt-30" v-if="customer.stripe_subscriptions.length">
                <div class="section-title">RECURRING PLANS</div>
                <div class="card mt-20" v-for="subscription in customer.stripe_subscriptions">
                    <table class="recurrent-table">
                        <thead>
                        <tr>
                            <th>Amount</th>
                            <th>Next installment</th>
                            <th>Payment method</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr class="subscription" >
                            <td class="amount">{{subscription.currency.symbol}}{{subscription.amount}}</td>
                            <td class="date">{{moment.utc(subscription.nextInstallment).local().format('MMM D, YYYY')}}</td>
                            <td class="pay-method" v-if="subscription.payment_method.type === 'card'">{{subscription.payment_method.brand.toUpperCase()}} ....{{subscription.payment_method.last4}}</td>
                            <td class="pay-method" v-else>{{subscription.payment_method.type.toUpperCase()}}</td>
                            <td style="vertical-align: middle;">
                                <a href="#" style="color:red;" @click.prevent="cancelSubscription(subscription.id)">Cancel</a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </section>
            <section class="mt-50">
                <div class="section-title">PAYMENT HISTORY</div>
                <div class="card mt-20">
                    <table class="payment-table">
                        <thead>
                        <tr>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>Pay method</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="payment in customer.payments">
                            <td>{{moment.utc(payment.created).local().format('MMM D, YYYY')}}</td>
                            <td>{{payment.currency.symbol}}{{payment.amount}}</td>
                            <td v-if="payment.payment_method && payment.payment_method.type === 'card'">{{payment.payment_method.type.toUpperCase()}} ....{{payment.payment_method.last4}}</td>
                            <td v-else-if="payment.payment_method">{{payment.payment_method.type.toUpperCase()}}</td>
                            <td v-else></td>
                            <td>
                                <a :href="payment.receipt_url" target="_blank">Download receipt</a>
                            </td>
                        </tr>

                        </tbody>
                    </table>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Loader from '@/components/Loader.vue'
import moment from 'moment'

export default {
    name: 'HomeView',
    components: {Header, Loader},
    data() {
        return {
            isLoading: true,
            moment: moment,
            customer: null
        }
    },
    mounted() {
        this.loadCustomerData();
    },
    methods: {
        loadCustomerData() {
            this.isLoading = true;

            axios.get(window.apiUrl + '/api/external/customer').then(r => {
                this.customer = r.data.data;
                this.isLoading = false;
            });
        },
        cancelSubscription(subscriptionId) {
            this.isLoading = true;

            if(confirm('Are you sure?')) {
                axios.post(window.apiUrl + '/api/external/customer/cancel-subscription/' + subscriptionId).then(r => {
                    this.loadCustomerData();
                });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.section-title {
    font-size: 23px;
    font-weight: bold;
    letter-spacing: 1px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    .manage-link {
        color: #777;
        font-size: 18px;
        font-weight: 300;
    }
}

thead {
    tr {

        th {
            width: 33%;
            font-size: 23px;
            font-weight: 400;
            opacity: 0.7;
        }
    }
}

.recurrent-table {


    .date,
    .amount,
    .pay-method {
        font-size: 42px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: 1px;
    }

}

.payment-table {
    thead {
        tr {
            border-bottom: 1px solid #eee;

            th {
                width: 25%;
                font-size: 23px;
                font-weight: 400;
                opacity: 0.7;
                text-align: left;
            }
        }
    }

    tbody {
        tr {
            td {
                font-size: 20px;
                line-height: 140%;
            }
        }
    }
}

.subscription {
    margin-bottom: 30px;
}
</style>
